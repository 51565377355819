<template>
  <div>
    <ManageAcademicJournal />
  </div>
</template>
<script>
import ManageAcademicJournal from "@/components/ManageAcademicJournal/ManageAcademicJournal";
export default {
  components: {
    ManageAcademicJournal,
  },
  created() {},
};
</script>
