<template>
  <v-form>
    <v-card color="#F5F5F5" flat>
      <v-row align="center" justify="center">
        <loading :active.sync="loading"></loading>
        <v-col cols="12" md="12" sm="12">
          <span
            style="
              font-family: 'IBM Plex Sans Thai';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 40px;
            "
          >
            วารสารวิชาการ
          </span>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pa-3 mt-4" color="#F5F5F5" flat>
      <v-row align="center" justify="center">
        <v-tabs align-with-title v-model="e1">
          <v-col
            cols="4"
            md="4"
            sm="4"
            :style="
              e1 == 0
                ? 'border-bottom: 3px solid #2ab3a3'
                : 'border-bottom: 1px solid #2ab3a3'
            "
          >
            <v-tab class="textResponsive">
              <span><b>นิเทศศาสตร์และนวัตกรรม</b></span>
            </v-tab>
          </v-col>
          <v-col
            cols="4"
            md="4"
            sm="4"
            :style="
              e1 == 1
                ? 'border-bottom: 3px solid #2ab3a3'
                : 'border-bottom: 1px solid #2ab3a3'
            "
          >
            <v-tab class="textResponsive">
              <span><b>การสื่อสารและการจัดการ</b></span>
            </v-tab>
          </v-col>
          <v-col
            cols="4"
            md="4"
            sm="4"
            style="border-bottom: 1px solid #2ab3a3"
          >
            <v-tab class="text-h6"> </v-tab>
          </v-col>

          <v-tabs-slider color="#2AB3A3"> </v-tabs-slider>

          <v-tab-item>
            <v-card class="pa-6" flat>
              <v-row>
                <v-col cols="12" md="3" sm="3">
                  <v-text-field
                    hide-details
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="ค้นหาวารสาร"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="9" sm="9">
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col class="text-right" v-if="e1 == 0">
                      <DialogAcademicJournal @getAll="getAll" />
                      <DialogAcademicJournalEdit
                        :itemEdit="itemEdit"
                        :openDialogEdit="openDialogEdit"
                        @getAll="getAll"
                        @closeDialogEdit="closeDialogEdit"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-data-table
                    :headers="headersBrandTh"
                    :items="items1"
                    :search="search"
                    disable-pagination
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:[`item.updatedAt`]="{ item }">
                      {{ convertDate(item.updatedAt) }}
                    </template>
                    <template v-slot:[`item.content_th`]="{ item }">
                      {{ item.content_th }}
                    </template>
                    <template v-slot:[`item.cover_img_url`]="{ item }">
                      <v-img
                        :src="item.cover_img_url"
                        width="120px"
                        class="ma-1"
                      ></v-img>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
                      <v-icon @click="UpdateNews(item)">mdi-pencil</v-icon>
                      <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
                    </template>
                    <template
                      v-slot:body="props"
                      v-if="isWindowWidthGreaterThan768"
                    >
                      <draggable
                        :list="props.items"
                        tag="tbody"
                        @change="DragItems1(props.items)"
                      >
                        <tr v-for="(item, index) in props.items" :key="index">
                          <td v-if="isWindowWidthGreaterThan768">
                            <v-icon small class="page__grab-icon">
                              mdi-arrow-all
                            </v-icon>
                          </td>
                          <td class="text-start">
                            {{ convertDate(item.updatedAt) }}
                          </td>
                          <td class="text-start">
                            <v-img
                              :src="item.cover_img_url"
                              width="120px"
                              class="ma-1"
                            ></v-img>
                          </td>
                          <td class="text-start">
                            {{ item.name_th }}
                          </td>
                          <td class="text-start">
                            {{ item.content_th }}
                          </td>
                          <td class="text-start">
                            {{ item.publisher_th }}
                          </td>

                          <td class="text-start">
                            <v-row justify="center">
                              <v-icon @click="ViewNews(item)"
                                >mdi-content-copy</v-icon
                              >
                              <v-icon @click="UpdateNews(item)"
                                >mdi-pencil</v-icon
                              >
                              <v-icon @click="DeleteItem(item)"
                                >mdi-delete</v-icon
                              >
                            </v-row>
                          </td>
                        </tr>
                      </draggable>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="pa-6">
              <v-row>
                <v-col cols="12" md="3" sm="3">
                  <v-text-field
                    hide-details
                    v-model="search1"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="ค้นหาวารสาร"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="9" sm="9">
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col class="text-right" v-if="e1 == 1">
                      <DialogAcademicJournal1 @getAll1="getAll1" />
                      <DialogAcademicJournalEdit1
                        :itemEdit="itemEdit"
                        :openDialogEdit="openDialogEdit"
                        @getAll1="getAll1"
                        @closeDialogEdit="closeDialogEdit"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-data-table
                    :headers="headersBrandTh"
                    :items="items2"
                    :search="search1"
                    disable-pagination
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:[`item.updatedAt`]="{ item }">
                      {{ convertDate(item.updatedAt) }}
                    </template>
                    <template v-slot:[`item.content_th`]="{ item }">
                      {{ item.content_th }} - {{ item.content_en }}
                    </template>
                    <template v-slot:[`item.cover_img_url`]="{ item }">
                      <v-img
                        :src="item.cover_img_url"
                        width="120px"
                        class="ma-1"
                      ></v-img>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
                      <v-icon @click="UpdateNews(item)">mdi-pencil</v-icon>
                      <v-icon @click="DeleteItem1(item)">mdi-delete</v-icon>
                    </template>
                    <template
                      v-slot:body="props"
                      v-if="isWindowWidthGreaterThan768"
                    >
                      <draggable
                        :list="props.items"
                        tag="tbody"
                        @change="DragItems2(props.items)"
                      >
                        <tr v-for="(item, index) in props.items" :key="index">
                          <td v-if="isWindowWidthGreaterThan768">
                            <v-icon small class="page__grab-icon">
                              mdi-arrow-all
                            </v-icon>
                          </td>
                          <td class="text-start">
                            {{ convertDate(item.updatedAt) }}
                          </td>
                          <td class="text-start">
                            <v-img
                              :src="item.cover_img_url"
                              width="120px"
                              class="ma-1"
                            ></v-img>
                          </td>
                          <td class="text-start">
                            {{ item.name_th }}
                          </td>
                          <td class="text-start">
                            {{ item.content_th }}
                          </td>
                          <td class="text-start">
                            {{ item.publisher_th }}
                          </td>

                          <td class="text-start">
                            <v-row justify="center">
                              <v-icon @click="ViewNews(item)"
                                >mdi-content-copy</v-icon
                              >
                              <v-icon @click="UpdateNews(item)"
                                >mdi-pencil</v-icon
                              >
                              <v-icon @click="DeleteItem1(item)"
                                >mdi-delete</v-icon
                              >
                            </v-row>
                          </td>
                        </tr>
                      </draggable>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-card>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
import DialogAcademicJournal from "../Dialogs/DialogAcademicJournal.vue";
import DialogAcademicJournalEdit from "../Dialogs/DialogAcademicJournalEdit.vue";
import DialogAcademicJournal1 from "../Dialogs/DialogAcademicJournal1.vue";
import DialogAcademicJournalEdit1 from "../Dialogs/DialogAcademicJournalEdit1.vue";
export default {
  components: {
    draggable,
    Loading,
    DialogAcademicJournal,
    DialogAcademicJournalEdit,
    DialogAcademicJournal1,
    DialogAcademicJournalEdit1,
  },
  data() {
    return {
      e1: 0,
      headersBrandTh: [],
      headersBrandTh1: [
        { text: "", value: "", align: "start" },
        { text: "วันที่เผยแพร่", value: "updatedAt", align: "start" },
        { text: "รูปวารสาร", value: "cover_img_url", align: "start" },
        {
          text: "ชื่อวารสาร",
          value: "name_th",
          align: "start",
        },
        { text: "ประจำช่วงเดือน", value: "content_th", align: "start" },
        { text: "สาขา", value: "publisher_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersBrandTh2: [
        { text: "วันที่เผยแพร่", value: "updatedAt", align: "start" },
        { text: "รูปวารสาร", value: "cover_img_url", align: "start" },
        {
          text: "ชื่อวารสาร",
          value: "name_th",
          align: "start",
        },
        { text: "ประจำช่วงเดือน", value: "content_th", align: "start" },
        { text: "สาขา", value: "publisher_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      dialog: false,
      isEdit: false,
      items1: [],
      items2: [],
      search: "",
      search1: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",

      itemEdit: {},
      openDialogEdit: false,
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAll();
    this.getAll1();
    document.title = "วารสารวิชาการ - GSCM Management System";
  },

  mounted() {
    window.scrollTo(0, 0);

    if (this.isWindowWidthGreaterThan768) {
      this.headersBrandTh = this.headersBrandTh1;
    } else {
      this.headersBrandTh = this.headersBrandTh2;
    }
  },

  methods: {
    async DragItems1(val) {
      this.loading = true;
      console.log("DragItems", this.items1);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/academicJournals/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.getAll();
      this.loading = false;
    },
    async DragItems2(val) {
      this.loading = true;
      console.log("DragItems", this.items2);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/academicJournals/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.getAll1();

      this.loading = false;
    },
    DownloadDocument(val) {
      console.log("val", val);
      window.open(val);
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY");
      // return moment(val).format("MM/DD/YYYY hh:mm");
    },

    async getAll() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${
          process.env.VUE_APP_API
        }/academicJournals?publisher_th=${"นิเทศศาสตร์และนวัตกรรม"}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAll1", response.data.data);
      this.items1 = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items1) {
        this.items1[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async getAll1() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${
          process.env.VUE_APP_API
        }/academicJournals?publisher_th=${"การสื่อสารและการจัดการ"}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAll2", response.data.data);
      this.items2 = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items2) {
        this.items2[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async DeleteItem(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/academicJournals/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll();
          this.getAll1();
        }
      });
    },
    async DeleteItem1(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/academicJournals/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll1();
        }
      });
    },
    goToCreate() {
      this.$router.push("CreateThesisAndResearch");
    },
    async UpdateNews(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/academicJournals/${val.id}`,
        auth
      );
      this.itemEdit = response.data.data;
      console.log(this.itemEdit);

      this.openDialogEdit = true;
    },
    closeDialogEdit() {
      this.openDialogEdit = false;
    },
    async ViewNews(val) {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/academicJournals/${val.id}`,
        auth
      );
      console.log("getOne", response.data.data);
      delete response.data.data.id;
      let duplicateData = response.data.data;

      await duplicateData.documentAcademicJournals.forEach(async (element) => {
        delete element.academicJournalId;
        delete element.id;
      });
      console.log("duplicateData", duplicateData);

      const response1 = await this.axios.post(
        `${process.env.VUE_APP_API}/academicJournals`,
        duplicateData,
        auth
      );
      console.log("response1", response1);
      console.log(response1.data.response_status);
      if (response1.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `คัดลอกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getAll();
        this.getAll1();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response1.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      this.loading = false;
    },
  },
};
</script>